import axios from "axios";

export default axios.create(
  process.env.NODE_ENV === "development"
    ? {
        baseURL: "http://127.0.0.1:8000/api",
      }
    : {
        baseURL: "http://api.seiconsultores.cl/api",
      }
);
